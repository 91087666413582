import { twMerge } from "tailwind-merge";
import React from "react";

interface IndicateVerticalProps {
  /** Whether the indicator should be on or off. */
  indicatorOn?: boolean;
  /** The amount of pixels the line will be translated when the indicator is off. */
  lineHide?: number;
  /** Whether the diamond on top of the line should be shown or not. */
  showDiamond?: boolean;
  /** Additional classes to be applied to the line. Use text-[color] to change the color of the line. */
  lineClassName?: string;
  /** Additional classes to be applied to the light. Use text-[color] to change the color of the light. */
  lightClassName?: string;
  /** Additional classes to be applied to the container. Use h-[height] to change the height of the container. */
  className?: string;
}

/**
 * This is a vertical line with a light at the bottom that is meant to be used in conjuction with `IndicatedIcon`.
 */
export const IndicateVertical: React.FC<IndicateVerticalProps> = ({
  indicatorOn = true,
  lineHide = 2,
  showDiamond = true,
  lineClassName = "",
  lightClassName = "",
  className = "h-12",
}) => {
  const lineTransform = indicatorOn ? "" : `translate(0, ${lineHide})`;
  const lineTransitionClass = indicatorOn ? "duration-700" : "duration-500";

  const lightTransitionClass = indicatorOn
    ? "duration-[2s] opacity-100"
    : "duration-700 opacity-0";

  return (
    <div
      className={twMerge(
        "fill-current stroke-current relative mx-auto w-full overflow-hidden",
        className,
      )}
    >
      {/* Line */}
      <svg
        viewBox={`0 0 20 50`}
        preserveAspectRatio="xMidYMin slice"
        xmlns="http://www.w3.org/2000/svg"
        className=""
      >
        <g
          stroke="currentColor"
          transform={lineTransform}
          className={twMerge(
            "transition-all",
            lineClassName,
            lineTransitionClass,
          )}
        >
          {/* Diamond on top */}
          {showDiamond && (
            <polygon
              fill="currentColor"
              points="10,0 11,1 10,2 9,1"
              strokeWidth=".3"
            />
          )}
          {/* Vertical line */}
          <line x1={10} y1={0} x2={10} y2={50} strokeWidth=".2" />
        </g>
      </svg>
      {/* Light from bottom */}
      <svg viewBox={`0 0 20 10`} className="absolute inset-x-0 bottom-0 w-full">
        <defs>
          <radialGradient id="radialGradient" className={lightClassName}>
            <stop offset="0%" stopColor="currentColor" stopOpacity=".5" />
            <stop offset="95%" stopColor="currentColor" stopOpacity="0" />
          </radialGradient>
        </defs>
        <circle
          cx={10}
          cy={10}
          r={6}
          fill="url('#radialGradient')"
          stroke="none"
          className={twMerge("transition-all", lightTransitionClass)}
        />
      </svg>
    </div>
  );
};
