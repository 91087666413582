import { useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";

interface LottieOwnershipPlotClickableProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, "onChange"> {
  onChange: (index: number) => void;
}

// Semi-hack to make lottie plots clickable
// Will traverse the lottie generated SVG and add the funcionality needed
export const LottieOwnershipPlotClickable: React.FC<
  LottieOwnershipPlotClickableProps
> = ({ onChange, children, ...rest }) => {
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (typeof document !== "undefined" && inView) {
      const $plotsGroup = document.querySelectorAll(
        "#lottie-plots svg > g > g",
      );

      $plotsGroup?.forEach((group, i) => {
        if (i < 4 && !group.getAttribute("data-index")) {
          group.setAttribute("data-index", String(i));

          const $plots = group.querySelectorAll(":scope > g");

          $plots.forEach((el, j) => {
            if (j < 4) {
              const el2 = el.lastElementChild;

              if (el2 && !el2.getAttribute("data-clickable")) {
                let changeToIndex = j;

                // We don't have consistency in the lottie nodes we need to handle this
                if (i === 3) {
                  if (j === 1) {
                    changeToIndex = 2;
                  } else if (j === 2) {
                    changeToIndex = 1;
                  }
                }

                el2.setAttribute("style", "cursor: pointer");
                el2.addEventListener("click", () => onChange(changeToIndex));
                el2.setAttribute("data-clickable", "true");
              }
            }
          });
        }
      });
    }
  }, [inView, onChange]);

  return (
    <div ref={ref} id="lottie-plots" {...rest}>
      {children}
    </div>
  );
};
