import { twMerge } from "tailwind-merge";
import React, { HTMLAttributes, useState } from "react";
import Character from "/assets/images/emberboy.png";
import CharacterGirl from "/assets/images/embergirl.png";
import { TitleJost, TextPoppins } from "@brightstarstudios/components";
import { motion, Variants } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavigationOptions } from "swiper/types";
import { Container } from "components/Container";
import { ButtonArrow } from "@brightstarstudios/components";
import { IndicatedIcon } from "components/IndicatedIcon";
import { IndicateVertical } from "components/IndicateVertical";
import Image from "next/image";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import SvgCircle from "assets/svgs/character-background.svg";
import SvgAdventure from "assets/svgs/icons/adventure.svg";
import SvgClassless from "assets/svgs/icons/classless.svg";
import SvgSocialSandbox from "assets/svgs/icons/social.svg";
import SvgSkills from "assets/svgs/icons/skills.svg";
import { BorderBox } from "components/BorderBox";

const ICON_DATA = [
  {
    name: "CHANGING THE GAMING LANDSCAPE FOREVER",
    Icon: SvgAdventure,
    description:
      "Set in Thanabus, a dynamic world full of wonder, mystery and adventure, and powered by our incredible new engine technology, Ember Sword will be the first proper MMO that you can play on any device, anywhere in the world, instantly.",
  },
  {
    name: "MECHANICAL FUN",
    Icon: SvgSocialSandbox,
    description:
      "Ember Sword is an open-world MMORPG with fast-paced combat and a dynamic class system allowing players to shape their own unique playstyle through their choice of different weapons & abilities. With a focus on accessible depth which rewards mastery without creating a barrier around accessibility.",
  },
  {
    name: "FRESH ADVENTURE WITH EVERY LOGIN",
    Icon: SvgClassless,
    description:
      "Like most RPGs, Ember Sword is set within a gripping, immersive universe and driven by a compelling and intriguing narrative. But where traditional games in the genre have static content which grows stale over time, our towns, villages and cities will be owned by and shaped by players, who will decide what kind of facilities and content they provide to other players in the world, ensuring that places are in a constant state of evolution.",
  },
  {
    name: "FORGED TOGETHER IN GLORY",
    Icon: SvgSkills,
    description:
      "We will prioritise the importance of social play within Ember Sword from the ground up, modernising what has made legendary MMOs so enduring, and becoming the memorable MMO experience for a whole new generation of players.",
  },
] as const;

const CAROUSEL_DATA = [
  {
    index: 0,
    image: Character,
  },
  {
    index: 1,
    image: CharacterGirl,
  },
] as const;

export const PlayerCarousel: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
  const [selectedIcon, setSelectedIcon] = useState(0);

  const descriptionVariant: Variants = {
    hidden: {
      opacity: 0,
      translateY: 100,
      position: "absolute",
      transition: {
        duration: 0.4,
      },
    },
    show: {
      opacity: 1,
      translateY: 0,
      position: "relative",
      transition: {
        duration: 0.4,
      },
    },
  };

  const navigation: NavigationOptions = {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  };

  return (
    <Container className="relative z-10 mt-16 flex flex-col items-center justify-center">
      <div className="grid grid-cols-1 lg:grid-cols-2" id="gameplay">
        <div className="swiper-grow relative flex items-center justify-center">
          <div className="absolute flex h-full w-full items-center">
            <div className="absolute left-1/2 flex w-[72%] -translate-x-1/2 items-center  justify-center">
              <SvgCircle className="w-full" />
            </div>

            {/* <Lottify
              animationData={"char-line-animation.json"}
              loop={true}
              autoplay={true}
            /> */}

            <div className="absolute -bottom-10 left-0 right-0 z-10 flex flex-row flex-nowrap items-center justify-center gap-6 lg:bottom-auto lg:left-auto lg:right-auto lg:top-24">
              <ButtonArrow
                direction="left"
                arrowClassName="text-white"
                circleClassName="text-tertiary-600"
                className="swiper-button-prev w-10"
              />
              <ButtonArrow
                direction="right"
                arrowClassName="text-white"
                circleClassName="text-tertiary-600"
                className="swiper-button-next w-10"
              />
            </div>
          </div>
          <Swiper
            modules={[Navigation, Autoplay, A11y]}
            navigation={navigation}
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 8000,
              disableOnInteraction: true,
            }}
            className="linear relative z-0 w-full"
          >
            {CAROUSEL_DATA &&
              CAROUSEL_DATA.map(({ image, index }) => (
                <SwiperSlide
                  key={index}
                  className="flex items-center justify-center"
                >
                  <span className="flex items-center justify-center">
                    <Image
                      src={image}
                      className="relative z-20"
                      alt="Slider image"
                    />
                  </span>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        <div className="relative h-full pb-20 pt-16 lg:py-0">
          <TitleJost
            size="small"
            variant="h6"
            className="text-center font-extrabold uppercase lg:hidden"
          >
            {ICON_DATA[selectedIcon].name}
          </TitleJost>

          <div className="flex h-full w-full flex-col flex-nowrap items-center justify-center pb-32 lg:pb-0">
            <div className="mt-12 flex h-full w-full justify-center lg:justify-start lg:border-b-2 lg:border-extra-100">
              {ICON_DATA.map(({ Icon, name }, i) => {
                const selected = selectedIcon === i;
                const opacityClass = selected ? "opacity-100" : "opacity-0";
                const iconColorClass = selected
                  ? "text-primary-red"
                  : "text-gray-500";
                const indicatorDelayClass = selected ? "delay-300" : "delay-0";

                return (
                  <div
                    key={name}
                    className="group flex flex-[1_0_0] cursor-pointer flex-col items-center  justify-between overflow-hidden lg:flex-[0_0_100px] xl:flex-[0_0_130px]"
                    onClick={() => {
                      setSelectedIcon(i);
                    }}
                  >
                    <TextPoppins
                      size="medium"
                      className={twMerge(
                        "hidden min-w-0 grow select-none overflow-hidden text-center font-bold uppercase text-tertiary-600 transition-opacity duration-500 lg:block",
                        opacityClass,
                      )}
                    >
                      {name}
                    </TextPoppins>
                    <IndicatedIcon
                      Icon={Icon}
                      showIndicator={selected}
                      className="mx-auto w-full"
                      indicatorClassName={twMerge(
                        "text-tertiary-600",
                        indicatorDelayClass,
                      )}
                      iconClassName={twMerge(
                        "group-hover:text-primary-red",
                        iconColorClass,
                      )}
                    />
                    <IndicateVertical
                      indicatorOn={selected}
                      lineHide={25}
                      showDiamond={false}
                      lineClassName="text-gray-500"
                      lightClassName="text-white"
                      className="hidden h-6 lg:block lg:h-20 xl:h-24"
                    />
                  </div>
                );
              })}
            </div>
            <div className="flex h-full min-h-[400px] w-full justify-center pt-4 lg:pt-20">
              {ICON_DATA.map(({ description, name }, i: number) => {
                const selected = selectedIcon === i;

                return (
                  <motion.div
                    key={name}
                    className="z-10 h-fit rounded-lg bg-tertiary-600 bg-opacity-80 text-center text-white"
                    variants={descriptionVariant}
                    animate={selected ? "show" : "hidden"}
                  >
                    <BorderBox>
                      <TextPoppins
                        key={name}
                        size="medium"
                        className="h-full w-full p-10 py-16"
                      >
                        {description}
                      </TextPoppins>
                    </BorderBox>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
