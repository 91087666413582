import { twMerge } from "tailwind-merge";
import React from "react";

export const BlockFullScreenContainer: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ children, className = "", ...rest }) => {
  return (
    <div className={twMerge("h-screen", className)} {...rest}>
      {children}
    </div>
  );
};
