import { twMerge } from "tailwind-merge";
import { Section } from "components/Section";
import Image from "next/image";
import { BlockTextCenter } from "components/blocks/BlockTextCenter";
import bgDiveStraightIn from "assets/images/bg-divestraightin.jpg";
import swordStoneImage from "assets/images/sword-stone.png";
import Link from "next/link";
import { ButtonAnimated } from "@brightstarstudios/components";
import { getModalRouteLink } from "utils/getModalRouteLink";
import { SectionClip } from "./SectionClip";
import SvgBarren from "assets/svgs/section-clips/barren.svg";

export const SectionJoinToday: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className = "",
}) => {
  return (
    <Section
      className={twMerge(
        "relative bg-gradient-to-b from-orange-200 to-primary-red lg:h-[700px]",
        className,
      )}
    >
      <SectionClip
        SvgElement={SvgBarren}
        flipX
        className="absolute inset-x-0 top-2 -translate-y-full fill-orange-200"
      />
      <span className="absolute inset-0 mask-linear-t">
        <Image
          src={bgDiveStraightIn}
          className="object-cover object-bottom"
          fill
          alt="Game background"
        />
      </span>
      <span className="absolute -bottom-1/4 left-1/2 right-24 top-1/4 hidden lg:block">
        <Image
          src={swordStoneImage}
          alt="Sword in stone"
          className="object-contain"
          fill
          sizes="(max-width: 1024px) 100px, 50vw"
        />
      </span>
      <BlockTextCenter title="Join us today!">
        <div className="flex justify-center">
          <Link href={getModalRouteLink("login")}>
            <ButtonAnimated color="red" label="Register" />
          </Link>
        </div>
      </BlockTextCenter>
    </Section>
  );
};
