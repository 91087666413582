import { twMerge } from "tailwind-merge";
import React from "react";
import { Section } from "components/Section";
import Image from "next/image";
import { Ownership } from "components/blocks/Ownership";
import { SectionClip } from "./SectionClip";
import bgTrueOwnership from "assets/images/bg-trueownership.jpg";
import SvgTreeRight from "assets/svgs/section-clips/tree-right.svg";

export const SectionNationsAndOwnership: React.FC<
  React.HTMLAttributes<HTMLElement>
> = ({ className = "" }) => {
  return (
    <Section className={twMerge("relative bg-dark-to-light-blue", className)}>
      <SectionClip
        SvgElement={SvgTreeRight}
        className="absolute inset-x-0 top-1 -translate-y-full fill-tertiary-600"
      />
      <span className="absolute bottom-0 left-0 z-0 h-full max-h-[950px] w-full mask-linear-t">
        <Image
          src={bgTrueOwnership}
          fill
          className="object-cover object-bottom opacity-50"
          alt="Introduction Background"
        />
      </span>
      <div className="relative grid-cols-1 lg:grid-cols-2 xl:pb-16">
        <Ownership id="ownership" />
      </div>
    </Section>
  );
};
