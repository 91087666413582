import { twMerge } from "tailwind-merge";
import {
  TitleJost,
  TextPoppins,
  DividerText,
  DividerUnderline,
} from "@brightstarstudios/components";
import React, { useEffect, useState, useRef } from "react";
import LandBlock from "assets/images/land_block.png";
import { motion, useInView } from "framer-motion";
import { AnimatedBannerReel } from "components/AnimatedBannerReel";
import landPlotData from "assets/lotties/land-plot-comp-default-active.json";
import Image from "next/image";
import { LottieOwnershipPlotClickable } from "components/LottieOwnershipPlotClickable";
import { Container } from "components/Container";
import { LandPlotAnimatedSquares } from "components/LandPlotAnimatedSquares";
import { PLOT_TYPE_DETAILS_WITHOUT_CAPITAL } from "data/plot-data";

import dynamic from "next/dynamic";
import { LottieRefCurrentProps } from "lottie-react";
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

export const Ownership: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props,
) => {
  const [selectedPlot, setSelectedPlot] = useState(0);

  const ref = useRef(null);
  const inView = useInView(ref);
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (inView && lottieRef.current) {
      const newPlot = PLOT_TYPE_DETAILS_WITHOUT_CAPITAL[selectedPlot];
      lottieRef.current.playSegments(
        [newPlot.landAnimation.start, newPlot.landAnimation.end],
        true,
      );
    } else {
      if (lottieRef.current) {
        lottieRef.current.pause();
      }
    }
  }, [inView, selectedPlot]);

  const changeSelectedPlot = (index: number) => {
    setSelectedPlot(index);
  };

  return (
    <div ref={ref} {...props} className="overflow-x-hidden">
      <Container className="relative py-20 pb-80">
        <DividerText text="Economy" color="blue" />
        <div className="mx-auto max-w-screen-2xl py-20 text-white">
          <div className="mx-auto mb-24 mt-10 max-w-3xl text-center">
            <TitleJost
              size="extra-large"
              variant="h1"
              className="mb-8 font-extrabold uppercase"
            >
              WHERE PLAYERS REIGN SUPREME
            </TitleJost>
            <DividerUnderline />
            <TextPoppins size="medium" className="mx-auto mb-6 max-w-2xl">
              We believe that players should be able to earn real value for
              their time invested in the game. This truly player-owned ecosystem
              puts the player in control of what happens to these cosmetics and
              what they want to do with them. Keep them? Trade them? Sell them?
              The choice is yours.
            </TextPoppins>
          </div>

          <div className="grid grid-cols-12 lg:gap-10">
            <div className="order-2 col-span-12 lg:order-1 lg:col-span-3">
              {/** Desktop */}
              <div className="relative -left-32 hidden w-[300px] lg:block">
                <AnimatedBannerReel selectedIndex={selectedPlot}>
                  {PLOT_TYPE_DETAILS_WITHOUT_CAPITAL.map((plot, i) => {
                    const buttonOpacity =
                      selectedPlot === i ? "opacity-100" : "opacity-30";
                    const PlotIcon = plot.icon;

                    return (
                      <button
                        className={twMerge(
                          "mb-8 h-[80px] transition-all duration-300 hover:scale-110 hover:opacity-100",
                          buttonOpacity,
                        )}
                        key={i}
                        onClick={() => changeSelectedPlot(i)}
                      >
                        <PlotIcon className="h-12 w-12" />
                      </button>
                    );
                  })}
                </AnimatedBannerReel>
              </div>
              {/** Mobile */}
              <div className="my-8 flex w-full justify-center lg:hidden">
                <div className="flex w-full max-w-xs justify-between">
                  {PLOT_TYPE_DETAILS_WITHOUT_CAPITAL.map((plot, i) => {
                    const buttonOpacity =
                      selectedPlot === i ? "opacity-100" : "opacity-30";
                    const PlotIcon = plot.icon;

                    return (
                      <button
                        className={twMerge(
                          "transition-all duration-300 hover:scale-110 hover:opacity-100",
                          buttonOpacity,
                        )}
                        key={i}
                        onClick={() => changeSelectedPlot(i)}
                      >
                        <PlotIcon className="h-12 w-12" />
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="order-3 col-span-12 justify-center lg:order-2 lg:col-span-3 lg:flex">
              <div>
                <div className="flex flex-col items-center justify-center">
                  <TitleJost
                    size="medium"
                    variant="h3"
                    className="mb-6 w-full text-center text-2xl font-extrabold uppercase text-white"
                  >
                    {PLOT_TYPE_DETAILS_WITHOUT_CAPITAL[selectedPlot].plotName}
                  </TitleJost>

                  <div className="relative z-10 flex h-60 w-60 flex-col items-center justify-center rounded-full border-4 border-tertiary-500 bg-gradient-radial from-tertiary-400 via-tertiary-500 to-tertiary-600">
                    <span className="absolute -top-3 block h-5 w-5 rotate-45 border-4 border-tertiary-500 bg-primary-teal-500" />

                    <div className="flex h-full w-full items-center">
                      <LandPlotAnimatedSquares
                        plotTypeDetails={
                          PLOT_TYPE_DETAILS_WITHOUT_CAPITAL[selectedPlot]
                        }
                      />
                    </div>
                    <TextPoppins
                      size="small"
                      className="absolute -bottom-3 uppercase text-primary-teal-500"
                    >
                      {PLOT_TYPE_DETAILS_WITHOUT_CAPITAL[selectedPlot].size}{" "}
                      &times;{" "}
                      {PLOT_TYPE_DETAILS_WITHOUT_CAPITAL[selectedPlot].size}
                    </TextPoppins>

                    <span className="absolute -bottom-3 block h-5 w-5 rotate-45 border-4 border-tertiary-500 bg-primary-teal-500" />
                  </div>
                </div>

                <div className="relative top-7 flex justify-center">
                  {PLOT_TYPE_DETAILS_WITHOUT_CAPITAL &&
                    PLOT_TYPE_DETAILS_WITHOUT_CAPITAL.map(
                      ({ plotDescription }, i) => (
                        <motion.div
                          key={i}
                          animate={{
                            translateY: selectedPlot === i ? 0 : 60,
                            opacity: selectedPlot === i ? 1 : 0,
                          }}
                          transition={{
                            duration: 0.5,
                          }}
                          className="absolute top-0 z-10 w-full max-w-md rounded-md bg-tertiary-500 bg-opacity-70 p-4 text-center lg:w-[500px]"
                        >
                          <div className="border border-extra-100 p-4 text-white">
                            <TextPoppins size="small">
                              {plotDescription}
                            </TextPoppins>
                          </div>
                        </motion.div>
                      ),
                    )}
                </div>
              </div>
            </div>
            {/** Land map plot container */}
            <div className="order-1 col-span-12 lg:order-3 lg:col-span-6">
              <div className="relative mx-auto max-w-2xl">
                <Image
                  src={LandBlock}
                  className="object-cover drop-shadow-lg"
                  alt="Land plot block"
                />
                <div className="flex justify-center">
                  <LottieOwnershipPlotClickable
                    onChange={changeSelectedPlot}
                    className="absolute top-0 w-3/4 -translate-y-[37%]"
                  >
                    <Lottie
                      lottieRef={lottieRef}
                      animationData={landPlotData}
                      loop={true}
                      autoplay={false}
                    />
                  </LottieOwnershipPlotClickable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
