import { twMerge } from "tailwind-merge";
import React from "react";
import { motion, Variants } from "framer-motion";
import { PlotTypeDetails } from "types/plot-data";
import SvgLandPlotSquare from "assets/svgs/land-plot-square.svg";

interface PlotSizeProps extends React.HTMLAttributes<HTMLDivElement> {
  plotTypeDetails: PlotTypeDetails;
}

export const LandPlotAnimatedSquares: React.FC<PlotSizeProps> = ({
  plotTypeDetails,
  className = "",
  ...rest
}) => {
  const createArrayGrid = (num: number) => {
    let arr: number[][] = [];

    for (let i = 1; i <= num; i++) {
      arr.push(new Array(i).fill(0));
    }

    for (let i = num - 1; i >= 1; i--) {
      arr.push(new Array(i).fill(0));
    }

    return arr;
  };

  const renderGrid = () => {
    const items = createArrayGrid(plotTypeDetails.size);

    const containerOffset = plotTypeDetails.size > 1 ? "25%" : 0;

    const STEP = 0.05;
    let count = 0;

    return (
      <div
        className="relative h-full"
        style={{ transform: `translateY(${containerOffset})` }}
      >
        {items.map((itemsOnRow, row) => {
          const rowStyle = {
            // 67% is approx the relative height of the square where the soil starts to appear
            transform: `translateY(-${row * 67}%)`,
            height: `${Math.ceil(100 / items.length)}%`,
          };

          return (
            <div key={`row-${row}`} style={rowStyle}>
              <div className="flex h-full justify-center">
                {itemsOnRow.map((_square, i) => {
                  const delay = count;
                  count += STEP;

                  const squareAnimation: Variants = {
                    hidden: {
                      translateY: -30,
                      scale: 0,
                    },
                    show: {
                      scale: 1,
                      translateY: 0,
                      transition: {
                        delay,
                      },
                    },
                  };

                  return (
                    <motion.div
                      key={`${plotTypeDetails.plotName}-${i}`}
                      className="relative h-full max-w-[66%]"
                      initial="hidden"
                      animate="show"
                      variants={squareAnimation}
                    >
                      <SvgLandPlotSquare className="h-full w-full" />
                    </motion.div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={twMerge("relative h-full w-full", className)} {...rest}>
      {renderGrid()}
    </div>
  );
};
