import { twMerge } from "tailwind-merge";
import React from "react";
import { Section } from "components/Section";
import { Container } from "components/Container";
import { BlockLatestNews } from "components/blocks/BlockLatestNews";
import { SectionClip } from "./SectionClip";
import SvgRobotTrees from "assets/svgs/section-clips/robot-and-trees.svg";
import { StrapiBlogPost } from "api/strapi-blog";

type SectionLatestNewsProps = React.HTMLAttributes<HTMLElement> & {
  blogPosts: StrapiBlogPost[];
};

export const SectionLatestNews: React.FC<SectionLatestNewsProps> = ({
  blogPosts,
  className,
}) => {
  if (blogPosts.length === 0) return null;

  return (
    <Section
      className={twMerge(
        "relative overflow-x-clip fill-white pb-32",
        className,
      )}
    >
      <SectionClip
        SvgElement={SvgRobotTrees}
        className="absolute inset-x-0 top-1 -translate-y-full "
      />

      <Container className="relative">
        <BlockLatestNews title="Latest News" blogs={blogPosts} />
      </Container>
    </Section>
  );
};
