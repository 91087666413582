import { twMerge } from "tailwind-merge";
import React from "react";
import { TitleJost, DividerUnderline } from "@brightstarstudios/components";
import { Parallax } from "react-scroll-parallax";
import { Container } from "components/Container";
import { ParallaxBgHeadline } from "components/ParallaxBgHeadline";

interface BlockTextMediaProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "content"> {
  /** The title for the block */
  title: string;
  /** An optinal secondary title to be shown in parallax behind the title */
  parallaxText?: string;
  /** The text for the block */
  text?: React.ReactNode;
  /** The content for the block */
  content?: React.ReactNode;
  /** The direction of the content */
  direction?: "left" | "right";
}

/**
 * This layout is used to display some text and content under a title.
 *
 * @example
 * <BlockTextMedia
 *   title="A title"
 *   parallaxText="Parallax"
 *   text="Lorem ipsum..."
 *   content="There once was a ship...
 *   direction="left"
 * />
 */
export const BlockTextMedia: React.FC<BlockTextMediaProps> = ({
  direction = "left",
  title,
  text,
  content,
  parallaxText,
  className = "",
  ...rest
}) => {
  const directionClass1 = direction === "left" ? "lg:order-1" : "lg:order-2";
  const directionClass2 = direction === "left" ? "lg:order-2" : "lg:order-1";

  const parallaxPlacement = direction === "left" ? "left-8" : "right-8";

  return (
    <>
      {parallaxText && (
        <Parallax speed={-10}>
          <ParallaxBgHeadline className={twMerge("", parallaxPlacement)}>
            {parallaxText}
          </ParallaxBgHeadline>
        </Parallax>
      )}
      <Container
        className={twMerge("py-32 text-tertiary-600", className)}
        {...rest}
      >
        <div className="relative z-10 grid grid-cols-1 gap-6 lg:grid-cols-2 xl:gap-16">
          <div
            className={twMerge(
              "flex flex-col items-center justify-center text-center lg:mr-10 lg:items-start lg:text-left",
              directionClass1,
            )}
          >
            <TitleJost
              size="large"
              variant="h1"
              className="mb-0 font-extrabold uppercase lg:ml-10 xl:ml-14"
            >
              {title}
            </TitleJost>
            <DividerUnderline className="w-full" />
            {!!text && text}
          </div>
          <div className={twMerge("", directionClass2)}>{content}</div>
        </div>
      </Container>
    </>
  );
};
