import { z } from "zod";

export const X_MIN_SOLARWOOD_MAP = 0;
export const X_MAX_SOLARWOOD_MAP = 200;
export const Y_MIN_SOLARWOOD_MAP = 0;
export const Y_MAX_SOLARWOOD_MAP = 200;

export enum PlotType {
  Regular,
  Settlement,
  Town,
  City,
  Capital,
}

export const PlotGroupCoordinatesSchema = z.object({
  x: z.number().min(X_MIN_SOLARWOOD_MAP).max(X_MAX_SOLARWOOD_MAP),
  y: z.number().min(Y_MIN_SOLARWOOD_MAP).max(Y_MAX_SOLARWOOD_MAP),
});

export const PlotGroupSchema = z
  .object({
    size: z.number().positive(),
    type: z.nativeEnum(PlotType),
  })
  .merge(PlotGroupCoordinatesSchema);

export type PlotGroup = z.infer<typeof PlotGroupSchema>;

export type Coordinates = z.infer<typeof PlotGroupCoordinatesSchema>;

export interface AssetGroupItem {
  name?: string;
  type: PlotType;
  coordinates?: Coordinates;
  price?: number;
  assetId?: number | null;
}

const PurchaseQuotaItemSchema = z.object({
  min: z.number().min(0),
  max: z.number().min(0),
  available: z.number().min(0),
});
export const PurchaseQuotaResponseSchema = z.object({
  payload: z.object({
    regular: PurchaseQuotaItemSchema,
    settlement: PurchaseQuotaItemSchema,
    town: PurchaseQuotaItemSchema,
    city: PurchaseQuotaItemSchema,
    capital: PurchaseQuotaItemSchema,
  }),
});

type PurchaseQuotaItem = z.infer<typeof PurchaseQuotaItemSchema>;
export type PurchaseQuotaResponse = z.infer<typeof PurchaseQuotaResponseSchema>;

export type PurchaseQuota = {
  [key in PlotType]: PurchaseQuotaItem;
};
