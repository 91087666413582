import { Section } from "components/Section";
import React from "react";
import { SectionClip } from "./SectionClip";
import { TextPoppins } from "@brightstarstudios/components";
import { GalleryCardAlternative } from "components/GalleryCardAlternative";
import { ModalVideoContent } from "types/modals";
import { useModal } from "hooks/useModal";
import { BlockTextMedia } from "components/blocks/BlockTextMedia";
import ImageTalkAboutEmberSword from "assets/images/talk-about-embersword.jpg";
import SvgRobotTrees from "assets/svgs/section-clips/robot-and-trees.svg";

export const SectionWhatIsEs = () => {
  const { showModal } = useModal();

  return (
    <Section className="relative ">
      <SectionClip
        SvgElement={SvgRobotTrees}
        className="absolute top-1 z-10 -translate-y-full fill-white"
      />
      <BlockTextMedia
        title="WHAT IS EMBER SWORD?"
        parallaxText="Ember Sword"
        direction="right"
        className="text-tertiary-600"
        text={
          <TextPoppins size="medium" className="mb-6 lg:ml-10 xl:ml-14">
            Ember Sword is a social sandbox MMORPG taking place in a
            player-driven universe where the adventure finds you. Built by a
            team of imaginative artists, engineers, and game designers, Ember
            Sword offers a unique community led and frictionless PvP and PVE
            player experience, and allows players to have true ownership of
            digital gaming assets.
          </TextPoppins>
        }
        content={
          <GalleryCardAlternative
            className="h-96 w-full"
            description={
              "people leading the creation of Ember Sword talk about their passion for the game"
            }
            thumbnail={ImageTalkAboutEmberSword.src}
            mediaType="YouTube"
            onClick={() => {
              showModal<ModalVideoContent>("video", {
                src: "https://youtu.be/d2x8iTW2Xms",
                ratio: "16:9",
              });
            }}
          />
        }
      />
    </Section>
  );
};
