import { twMerge } from "tailwind-merge";
import React, { HTMLAttributes } from "react";
import Image from "next/image";
import SvgImageExpand from "assets/svgs/icons/expand.svg";
import SvgPlay from "assets/svgs/icons/play-alternative.svg";
import { BorderBox } from "./BorderBox";

interface GalleryCardProps extends HTMLAttributes<HTMLDivElement> {
  mediaType: "YouTube" | "Image";
  thumbnail: string;
  description: string;
}

export const GalleryCardAlternative: React.FC<GalleryCardProps> = ({
  className = "",
  thumbnail,
  mediaType,
  description,
  ...rest
}) => {
  const IconType = mediaType === "YouTube" ? SvgPlay : SvgImageExpand;

  return (
    <div
      className={twMerge(
        "group relative row-span-1 h-60 cursor-pointer overflow-hidden rounded-lg drop-shadow-lg transition-all duration-300 hover:scale-105 hover:drop-shadow-xl xl:h-72",
        className,
      )}
      {...rest}
    >
      <BorderBox className="h-full w-full text-white">
        <Image
          src={thumbnail}
          alt={description}
          fill
          className="object-cover"
          sizes="(max-width: 640px) 100vw, 720px"
        />
        <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-10">
          <div className="rounded-md bg-tertiary-600 p-2 drop-shadow-md lg:p-3">
            <IconType
              width={25}
              height={25}
              className="fill-primary-teal-600"
            />
          </div>
        </div>
      </BorderBox>
    </div>
  );
};
