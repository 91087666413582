import { PlotTypeDetails } from "types/plot-data";
import {
  PlotGroupSchema,
  PlotType,
  X_MAX_SOLARWOOD_MAP,
  Y_MAX_SOLARWOOD_MAP,
} from "types/marketplace";
import { z } from "zod";
import regularImage from "/public/metadata/land/card_regular.png";
import settlementImage from "/public/metadata/land/card_settlement.png";
import townImage from "/public/metadata/land/card_town.png";
import cityImage from "/public/metadata/land/card_city.png";
import capitalImage from "/public/metadata/land/card_capital.png";
import SvgPlotRegular from "assets/svgs/icons/plots/plot-regular.svg";
import SvgPlotSettlement from "assets/svgs/icons/plots/plot-settlement.svg";
import SvgPlotTown from "assets/svgs/icons/plots/plot-town.svg";
import SvgPlotCity from "assets/svgs/icons/plots/plot-city.svg";
import { IS_DEV_ENVIRONMENT } from "utils/isDevEnvironment";
import solarwoodPlottypeGrid from "data/solarwood-plottype-grid.json";

// Load different groups based on environment.
let solarwoodGroupsForSale: unknown;
let solarwoodGroupGrid: unknown;
if (IS_DEV_ENVIRONMENT) {
  solarwoodGroupsForSale = require("data/solarwood-test-groups-for-sale.json");
  solarwoodGroupGrid = require("data/solarwood-test-group-grid.json");
} else {
  solarwoodGroupsForSale = require("data/solarwood-prod-groups-for-sale.json");
  solarwoodGroupGrid = require("data/solarwood-prod-group-grid.json");
}

// Parse the plottype grid and groups
export const PLOTTYPE_GRID = z
  .array(z.array(z.nativeEnum(PlotType)).length(X_MAX_SOLARWOOD_MAP))
  .length(Y_MAX_SOLARWOOD_MAP)
  .parse(solarwoodPlottypeGrid);
export const GROUPS_FOR_SALE = z
  .array(PlotGroupSchema)
  .parse(solarwoodGroupsForSale);
export const GROUPS_GRID = z
  .array(z.array(z.number().nullable()).length(X_MAX_SOLARWOOD_MAP))
  .length(Y_MAX_SOLARWOOD_MAP)
  .parse(solarwoodGroupGrid);

export const PLOT_TYPE_DETAILS: PlotTypeDetails[] = [
  {
    type: PlotType.Regular,
    size: 1,
    plotName: "Regular Plot",
    plotDescription:
      "Do you aspire to earn the title of a Sir or a Lady? If so, you can easily get your hands on any number of Regular Plots of land in Solarwood. This land is perfect for those players who want to share in the rewards but want to put less time into land ownership. This land gives you bragging rights and comes with a x1 per plot multiplier for your share of the trading in Ember Sword.",
    icon: SvgPlotRegular,
    iconUrl: "/images/plots/colored-plot-settlement.png", // TODO: This is wrong
    iconDisabledUrl: "/images/plots/grayscale-plot-settlement.png", // TODO: This is wrong
    image: regularImage,
    video: "/metadata/land/card_regular.mp4",
    landAnimation: { start: 176, end: 220 },
    colorClassName: "land-regular",
  },
  {
    type: PlotType.Settlement,
    size: 1,
    plotName: "Settlement Plot",
    plotDescription:
      "Do you seek a Baron or Baroness title? Look at you climbing up the ladder! These Settlement Plot landowners know how to keep up appearances with the option to place Tier 1 buildings of their choice — which players won’t be able to resist visiting. You will also be able to give players quests. With all these attractions, you will be well rewarded with a multiplier of 10x per plot for your share of the trading in Ember Sword.",
    icon: SvgPlotSettlement,
    iconUrl: "/images/plots/colored-plot-settlement.png",
    iconDisabledUrl: "/images/plots/grayscale-plot-settlement.png",
    image: settlementImage,
    video: "/metadata/land/card_settlement.mp4",
    landAnimation: { start: 132, end: 176 },
    colorClassName: "land-settlement",
  },
  {
    type: PlotType.Town,
    size: 2,
    plotName: "Town",
    plotDescription:
      "For the players who know what they want and how to get it, you would be after the title of Earl or Countess. You have just acquired yourself an entire Town. Good for you! You love to attract attention to your land and are able to do so with Tier 2 buildings, and the ability to create quests. You are also rewarded with a multiplier of 50x per plot for your share of the trading in Ember Sword.",
    icon: SvgPlotTown,
    iconUrl: "/images/plots/colored-plot-town.png",
    iconDisabledUrl: "/images/plots/grayscale-plot-town.png",
    image: townImage,
    video: "/metadata/land/card_town.mp4",
    landAnimation: { start: 88, end: 132 },
    colorClassName: "land-town",
  },
  {
    type: PlotType.City,
    size: 4,
    plotName: "City",
    plotDescription:
      "For avid entrepreneurs, acquiring this land will earn the right to the highly respected and coveted title of Duke or Duchess. Players cannot stay away from your mammoth, sprawling City Plots. Your bustling area will give you the ability to build Tier 3 buildings and issue player quests. City owners are granted a whopping 125x per plot multiplier for their share of the trading in Ember Sword.",
    icon: SvgPlotCity,
    iconUrl: "/images/plots/colored-plot-city.png",
    iconDisabledUrl: "/images/plots/grayscale-plot-city.png",
    image: cityImage,
    video: "/metadata/land/card_city.mp4",
    landAnimation: { start: 44, end: 88 },
    colorClassName: "land-city",
  },
  {
    type: PlotType.Capital,
    size: 10,
    plotName: "Capital City",
    plotDescription:
      "Capital Cities are the largest plots of land in the game and are the center of all activity.",
    icon: SvgPlotCity,
    iconUrl: "/images/plots/colored-plot-capital.png",
    iconDisabledUrl: "/images/plots/grayscale-plot-capital.png",
    image: capitalImage,
    video: "/metadata/land/card_capital.mp4",
    landAnimation: { start: 0, end: 44 }, // TODO: Check this
    colorClassName: "land-capital",
  },
];

export const PLOT_TYPE_DETAILS_WITHOUT_CAPITAL = PLOT_TYPE_DETAILS.slice(
  0,
  PLOT_TYPE_DETAILS.length - 1,
);
