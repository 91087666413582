import {
  DetailedHTMLProps,
  useCallback,
  useEffect,
  useRef,
  VideoHTMLAttributes,
} from "react";

const TAILWIND_LG_BREAKPOINT = 1024 as const;

type VideoDefferedForSmallScreensProps = {
  /** The width at which the video will load */
  loadWidth?: number;
} & Omit<
  DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>,
  "preload"
>;

/**
 * This component will defer loading a video until the screen is large enough.
 * This is useful for videos that are too large to load on mobile devices.
 *
 * @example
 * <VideoDefferedForSmallScreens loadWidth={1024}>
 *   <source src='videos/my_video.mp4' type='video/mp4' />
 * </VideoDefferedForSmallScreens>
 **/
export const VideoDefferedForSmallScreens: React.FC<
  VideoDefferedForSmallScreensProps
> = ({ loadWidth = TAILWIND_LG_BREAKPOINT, children, ...props }) => {
  const ref = useRef<HTMLVideoElement>(null);

  // This function will trigger the video to load
  const loadVideo = useCallback(async () => {
    if (ref.current) {
      ref.current.preload = "auto";

      try {
        await ref.current.play();
      } catch (error: any) {
        if (error?.name === "AbortError") {
          // Display a friendly message to the user
          console.log(
            "Sorry, the video playback was paused to save power. Please try again later.",
          );
        } else {
          // Handle other errors accordingly
          console.error("Oops! Something went wrong.", error);
        }
      }
    }
  }, []);

  useEffect(() => {
    const watchMedia = window.matchMedia(`(min-width: ${loadWidth}px)`);
    if (watchMedia.matches) {
      // We are on a large enough screen, so we can load the video
      loadVideo();
    } else {
      // We are on a small screen, so we need to wait for the media query to change
      watchMedia.addEventListener("change", loadVideo, { once: true });
      return () => {
        watchMedia.removeEventListener("change", loadVideo);
      };
    }
  }, [loadWidth, loadVideo]);

  return (
    <video preload="none" ref={ref} {...props}>
      {children}
    </video>
  );
};
