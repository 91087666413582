import { twMerge } from "tailwind-merge";
import React from "react";
import { Section } from "components/Section";
import { SectionClip } from "./SectionClip";
import Image from "next/image";
import { TextPoppins } from "@brightstarstudios/components";
import { BlockTextMedia } from "components/blocks/BlockTextMedia";
import { PlayerCarousel } from "components/blocks/PlayerCarousel";
import gameplay from "assets/images/hero-pose.jpg";
import bgGameplay from "assets/images/bg-gameplay.jpg";
import SvgRobotTrees from "assets/svgs/section-clips/robot-and-trees.svg";
import { GalleryCardAlternative } from "components/GalleryCardAlternative";
import { useModal } from "hooks/useModal";
import { ModalVideoContent } from "types/modals";

export const SectionCraftingNewMmorpg: React.FC<
  React.HTMLAttributes<HTMLElement>
> = ({ className = "" }) => {
  const { showModal } = useModal();

  return (
    <Section
      className={twMerge(
        "relative bg-gradient-to-b from-tertiary-600 via-primary-teal-600 to-transparent pt-0",
        className,
      )}
    >
      <SectionClip
        SvgElement={SvgRobotTrees}
        className="absolute inset-x-0 top-2 -translate-y-full fill-tertiary-600"
      />
      <div className="relative pt-32">
        <BlockTextMedia
          title="Gameplay"
          className="text-white"
          parallaxText="Gameplay"
          text={
            <TextPoppins size="medium" className="mb-6 lg:ml-10 xl:ml-14">
              We are fusing mechanically-fun combat with a dynamic class system
              and broad options for horizontal progression, to give players the
              opportunity to craft a path to glory which is uniquely their own;
              and every login feels fresh thanks to the evolving landscapes
              shaped and built by the players themselves.
            </TextPoppins>
          }
          content={
            <div className="flex justify-end">
              <GalleryCardAlternative
                className="aspect-video"
                description={
                  "people leading the creation of Ember Sword talk about their passion for the game"
                }
                thumbnail={gameplay.src}
                mediaType="YouTube"
                onClick={() => {
                  showModal<ModalVideoContent>("video", {
                    src: "https://youtu.be/db5CtT_khNw",
                    ratio: "16:9",
                  });
                }}
              />
            </div>
          }
        />
        <PlayerCarousel />
      </div>
      <span className="absolute bottom-0 left-0 z-0 h-full max-h-[950px] w-full bg-primary-teal-600 mask-linear-t">
        <Image
          src={bgGameplay}
          fill
          className="object-cover object-bottom"
          alt="Introduction Background"
        />
      </span>
    </Section>
  );
};
